import { createHttpEndpoint } from '../../utils'

import {
  type PostClientAddressData,
  type RetrieveClientShippingAddressData,
  type UpdateClientShippingAddressPayload,
} from './types'

/**
 * Update Client shipping or billing address
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-client-api/definition#tag/shipping-address/operation/postClientAddresse}
 */
export const postAddress = createHttpEndpoint<PostClientAddressData>({
  method: 'POST',
  operationId: 'postClientAddress',
  path: '/bm/client/address',
  transformRequestToSnakeCase: true,
})

/**
 * Update Client shipping address
 * DevPortal not updated for this API, see here: https://imperatorz.slack.com/archives/C01BHDKAA4T/p1736932704098299
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-client-api/definition}
 */
export const postShippingAddress =
  createHttpEndpoint<UpdateClientShippingAddressPayload>({
    method: 'POST',
    operationId: 'postClientAddress',
    path: '/bm/client/addresses/v2/shipping',
  })

/**
 * Retrieve Client shipping address
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-client-api/definition#tag/shipping-address/operation/retrieveClientShippingAddress}
 */
export const getShippingAddress =
  createHttpEndpoint<RetrieveClientShippingAddressData>({
    method: 'GET',
    operationId: 'retrieveClientShippingAddress',
    path: '/bm/client/addresses/shipping',
  })
